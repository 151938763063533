import React, { useEffect, useState } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { getContentfulClient } from '../../lib/roptoClientLibrary';

import './legal-notice.scss';

const LegalNotice = () => {
  const [legalNotice, setLegalNotice] = useState();

  const contentfulClientOpts = {
    accessToken: process.env.CONTENTFUL_ACCESS_TOKEN,
    space: process.env.CONTENTFUL_SPACE_ID,
  };

  const contentfulPPClient = getContentfulClient(
    contentfulClientOpts.accessToken,
    contentfulClientOpts.space,
  );


  useEffect(() => {
    async function fetchPP() {
      const response = await contentfulPPClient.getEntry(
        process.env.CONTENTFUL_LN_ID,
      );
      setLegalNotice(response);
    }
    fetchPP();
  }, []);

  return (
    <div className="legal-notice">
      <div className="legal-notice__container">
        { legalNotice
          && <h1 className="legal-notice__title">{legalNotice.fields.title}</h1>
        }
        <div className="legal-notice__content">
          { legalNotice && documentToReactComponents(legalNotice.fields.body) }
        </div>
      </div>
    </div>
  );
};

export default LegalNotice;
