import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import LegalNotice from '../components/LegalNotice/legal-notice';

const LegalNoticePage = ({ location }) => (
  <Layout location={location}>
    <SEO title="Legal Notice" />
    <LegalNotice />
  </Layout>
);

LegalNoticePage.propTypes = {
  location: PropTypes.shape().isRequired,
};

export default LegalNoticePage;
